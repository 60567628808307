<template>
  <div class="warehouses has-text-centered">
    <canvas id="c" :width="rectWidth" :height="rectHeight" style="border:1px solid #c3c3c3;"></canvas>
    {{current_node}}
  </div>
</template>

<script>export default {
    name: 'WareWorld',
    
    data(){
        return {
            canvas: null,
            rectWidth: 1280,
            nextwarehouse: 0, 
            deliveryDatesHours: [],
            current_node: null,
            data: [
                {name: "Munich", x: 0, y: 0, w: 0, h: 0, deliveries: [
                    {truck: 'HAWE001', load: 40, date: '19/12/2020', time:
                        '09:00', x: 0, y:0, w: 0, h: 0, arrival: {
                            date: '19/12/2020', time: '14:25'
                        } 
                    }, 
                    {truck: 'HAWE001', load: 40, date: '19/12/2020', time: '09:00', x: 0, y:0, w: 0, h: 0, arrival: {
                        date: '19/12/2020', time: '14:25'
                    }}, 
                    {truck: 'HAWE001', load: 40, date: '19/12/2020', time: '09:00', x: 0, y:0, w: 0, h: 0, arrival: {
                        date: '19/12/2020', time: '12:25'
                    }}, 
                    {truck: 'HAWE001', load: 40, date: '19/12/2020', time: '09:00', x: 0, y:0, w: 0, h: 0, arrival: {
                        date: '19/12/2020', time: '13:25'
                    }}, 
                    {truck: 'HAWE002', load: 80, date: '19/12/2020', time: '09:00', x: 0, y:0, w: 0, h: 0, arrival: {
                        date: '19/12/2020', time: '14:25'
                    }}      
                ]
                },
                {name: "Bergheim", x: 0, y: 0, w: 0, h: 0, deliveries: [
                    {truck: 'HAWE003', load: 60, date: '21/12/2020', time: '09:00', x: 0, y:0, w: 0, h: 0, arrival: {
                        date: '19/12/2020', time: '14:25'
                    }}, 
                    {truck: 'HAWE006', load: 80, date: '20/12/2020', time: '09:40', x: 0, y:0, w: 0, h: 0, arrival: {
                        date: '20/12/2020', time: '14:35'
                    }}, 
                    {truck: 'HAWE004', load: 70, date: '20/12/2020', time: '10:00', x: 0, y:0, w: 0, h: 0, arrival: {
                        date: '19/12/2020', time: '14:25'
                    }}, 
                ]
                },
                {name: "Bonn", x: 0, y: 0, w: 0, h: 0, deliveries: [
                    {truck: 'HAWE0012', load: 40, date: '19/12/2020', time: '09:00', x: 0, y:0, w: 0, h: 0, arrival: {
                        date: '19/12/2020', time: '14:25'
                    }}, 
                    {truck: 'HAWE0020', load: 90, date: '19/12/2020', time: '09:00', x: 0, y:0, w: 0, h: 0, arrival: {
                        date: '19/12/2020', time: '14:25'
                    }}      
                ]
                },
                {name: "Dusseldorf", x: 0, y: 0, w: 0, h: 0, deliveries: [
                    {truck: 'HAWE0011', load: 80, date: '19/12/2020', time: '09:00', x: 0, y:0, w: 0, h: 0, arrival: {
                        date: '19/12/2020', time: '14:25'
                    }}, 
                    {truck: 'HAWE0023', load: 70, date: '19/12/2020', time: '09:00', x: 0, y:0, w: 0, h: 0, arrival: {
                        date: '19/12/2020', time: '14:25'
                    }},
                    {truck: 'HAWE0021', load: 90, date: '19/12/2020', time: '09:45', x: 0, y:0, w: 0, h: 0, arrival: {
                        date: '19/12/2020', time: '14:25'
                    }},

                ]
                }
            ]
        }
    },
    created(){
        if(this.$route.params.id) {
			this.$parent.event_jobs = []
			this.$parent.event_details.Jobs = []
			this.jobs = []
			this.$parent.currentEvent = this.$route.params.id 
			this.$parent.getProject()	
		}


        this.warehouses_objs = this.data

        for(var i = 0; i < this.data.length; i++){
            for(var j = 0; j < this.data[i].deliveries.length; j++){
                if(!this.deliveryDatesHours[this.data[i].deliveries[j].date])
                    this.deliveryDatesHours[this.data[i].deliveries[j].date] = []
                if(this.data[i].deliveries[j].date in this.deliveryDatesHours){
                    let found = false

                    for(var k = 0; k < this.deliveryDatesHours[this.data[i].deliveries[j].date].length; k++){
                        console.log(this.deliveryDatesHours[this.data[i].deliveries[j].date][k])
                        if(this.deliveryDatesHours[this.data[i].deliveries[j].date][k].time
                            == this.data[i].deliveries[j].arrival.time) found = true
                    }
                    if(found == false)
                        this.deliveryDatesHours[this.data[i].deliveries[j].date].push(
                            {
                                time: this.data[i].deliveries[j].arrival.time,
                                x: 0,
                                y: 0, 
                            }
                        )
                    
                    // sort times
                    this.deliveryDatesHours[this.data[i].deliveries[j].date] =
                        this.deliveryDatesHours[this.data[i].deliveries[j].date].sort((a, b) =>
                        a.time.localeCompare(b.time));
                }
                // else this.deliveryDatesHours[this.data[i].deliveries[j].date] =
                    // []
            }
        }
        this.deliveryDatesHours = this.deliveryDatesHours.sort((a, b) => {
            a.localeCompare(b)
        })
        console.log(this.deliveryDatesHours)
        setTimeout(() => {
            this.getSceneWH()
        }, 0)
    },
    computed: {
        rectHeight() {
            return this.nextwarehouse + 50
        }

    },
    methods: {
        getSceneWH(){
            let line_gap = 2
            let next_warehouse = 0
            let warehouses_objs = this.warehouses_objs

            for(let i = 0; i < warehouses_objs.length; i++){
                warehouses_objs[i].x = 0
                warehouses_objs[i].y = 38 + next_warehouse
                warehouses_objs[i].w = 90 //10 + (7*warehouses_objs[i].name.length)
                warehouses_objs[i].h = 20

                for(let j = 0; j < warehouses_objs[i].deliveries.length; j++){
                    next_warehouse = warehouses_objs[i].y+line_gap+5 + (j*30)
                }
            }
            this.nextwarehouse = next_warehouse
            setTimeout(() => { 
                this.drawScene()
            }, 0)
        },
        drawScene(){
            let line_gap = 2
            let load_font_color = "#ffffff"

            var c = document.getElementById("c")
            var ctx = c.getContext("2d")
            this.canvas = ctx

            this.canvas.clearRect(0, 0, this.rectWidth, this.rectHeight);
            this.canvas.fillStyle = "rgba(0, 0, 200, 1)";
            this.canvas.fillRect(90,0, 1,this.rectHeight);

            let next_warehouse = 0
            let warehouses_objs = this.warehouses_objs
           
            let nextdate = 0
            let lasthour = 0
            let dates = 0
            Object.entries(this.deliveryDatesHours).forEach(entry => {
                const [key, value] = entry;
                this.canvas.font = "bold 10px Arial";
                this.canvas.fillStyle = "#000000";
                this.canvas.fillText(key, 
                    140 + 60 + lasthour, 
                    12
                );
                if(dates != 0) this.canvas.fillRect(60+lasthour, 0,1, 800);
                // this should be arrival times
                for( var i = 0; i < value.length; i++){
                    this.canvas.font = "bold 10px Arial";
                    this.canvas.fillStyle = "#000000";
                    this.canvas.fillText(value[i].time, 
                        240 + 60 * i + nextdate, //(i == 0 ? 110 : i) + (i != 0 ? 110 : 0), 
                        32
                    );
                    lasthour = 240+60*i + nextdate
                }
                nextdate = lasthour
                dates++
            });

            for(let i = 0; i < warehouses_objs.length; i++){
                this.canvas.fillStyle = "rgba(0, 0, 200, 1)";
                warehouses_objs[i].x = 0
                warehouses_objs[i].y = (i == 0 ? 38 : 18) + next_warehouse
                warehouses_objs[i].w = 90 //10 + (7*warehouses_objs[i].name.length)
                warehouses_objs[i].h = 20

                this.canvas.fillRect(0,next_warehouse + 17,1280,1);

                if(i == 0) {
                    this.canvas.fillStyle = "gray";
                    this.canvas.fillRect(0,next_warehouse + 37,1280,1);
                }

                this.canvas.fillStyle = "rgba(0, 0, 200, 1)";
                this.canvas.fillRect(
                    warehouses_objs[i].x,
                    warehouses_objs[i].y,
                    warehouses_objs[i].w,
                    warehouses_objs[i].h
                );

                for(let j = 0; j < warehouses_objs[i].deliveries.length; j++){
                    // time
                    if(j == 0 || (j != 0 &&
                        warehouses_objs[i].deliveries[j].time !=
                        warehouses_objs[i].deliveries[j-1].time)){
                        this.canvas.font = "10px Arial";
                        this.canvas.fillStyle = "#000000";
                        this.canvas.fillText(warehouses_objs[i].deliveries[j].time, 
                            95, 
                            warehouses_objs[i].y+line_gap+14 + (j*30)
                        );
                    }

                    //truck line 
                    this.canvas.fillStyle = "rgba(0, 0, 0, 1)";
                    warehouses_objs[i].deliveries[j].x = 130
                    warehouses_objs[i].deliveries[j].y =
                        warehouses_objs[i].y+line_gap+3 + (j * 30),
                    warehouses_objs[i].deliveries[j].w = 120
                    warehouses_objs[i].deliveries[j].h = 13

                    this.canvas.fillRect(
                        warehouses_objs[i].deliveries[j].x,
                        warehouses_objs[i].deliveries[j].y,
                        warehouses_objs[i].deliveries[j].w,
                        warehouses_objs[i].deliveries[j].h
                    );
                    


                    // truck name
                    this.canvas.font = "bold 10px Arial";
                    this.canvas.fillStyle = "#ffffff";
                    this.canvas.fillText(warehouses_objs[i].deliveries[j].truck, 
                        147, 
                        warehouses_objs[i].y+line_gap+14 + (j*30)
                    );

                    //////////////////////////////////////////////////////////////////// truck load 
                    this.canvas.beginPath();
                    this.canvas.arc(135, warehouses_objs[i].y+line_gap+10 + (j*30), 10, 0, 2 * Math.PI);
                    if(warehouses_objs[i].deliveries[j].load > 80){
                        this.canvas.fillStyle = "red";
                        load_font_color = "#ffffff";
                    }
                    else if(warehouses_objs[i].deliveries[j].load <= 80 && warehouses_objs[i].deliveries[j].load > 60){
                        this.canvas.fillStyle = "yellow";
                        load_font_color = "#000000";
                    }
                    else{
                        this.canvas.fillStyle = "green";
                        load_font_color = "#ffffff";
                    }
                    this.canvas.fill();
                    this.canvas.stroke();
                    // load value text
                    this.canvas.font = "bold 10px Arial";
                    this.canvas.fillStyle = load_font_color;
                    this.canvas.fillText(warehouses_objs[i].deliveries[j].load, 
                        130, 
                        warehouses_objs[i].y+line_gap+14 + (j*30)
                    );
                    /////////////////////////////////////////////////////////////////////////////////

                    // y level for next warehouse
                    next_warehouse = warehouses_objs[i].y+line_gap+5 + (j*30)
                }
                this.canvas.font = "bold 11px Monospace";
                this.canvas.fillStyle = "#ffffff";
                this.canvas.fillText(warehouses_objs[i].name.toUpperCase(), 
                    warehouses_objs[i].x + 4, 
                    warehouses_objs[i].y + 14);
            }
            // this.nextwarehouse = next_warehouse
            c.addEventListener('click', (e) => {
                // console.log('click: ' + e.offsetX + '/' + e.offsetY);
                this.current_node = null
                var rect = this.collides(warehouses_objs, e.offsetX, e.offsetY);
                if (rect) {
                    console.log('collision: ', rect);
                    this.current_node = rect
                } else {
                    console.log('no collision');
                }
            })
        },
        collides(rects, x, y) {
            var isCollision = false;
            for (var i = 0, len = rects.length; i < len; i++) {
                var left = rects[i].x, right = rects[i].x+rects[i].w;
                var top = rects[i].y, bottom = rects[i].y+rects[i].h;
                if (right >= x
                    && left <= x
                    && bottom >= y
                    && top <= y) {
                    isCollision = rects[i];
                }
                for(var j = 0; j < rects[i].deliveries.length; j++){
                    left = rects[i].deliveries[j].x, 
                    right = rects[i].deliveries[j].x+rects[i].deliveries[j].w;
                    top = rects[i].deliveries[j].y, 
                    bottom = rects[i].deliveries[j].y+rects[i].deliveries[j].h;
                    if (right >= x
                        && left <= x
                        && bottom >= y
                        && top <= y) {
                        isCollision = rects[i].deliveries[j]
                        console.log(isCollision)
                    }
                }
            }

            return isCollision;
        }
    },
    mounted(){
        var c = document.getElementById("c")
        var ctx = c.getContext("2d")
        this.canvas = ctx
    }
}
</script>

<style scoped>

</style>
